import React from 'react'

const HomePage = ({ products }) => {
    return (
        <div>
            <h2>Welcome to stoners House</h2>
            
        </div>
    )
}

export default HomePage
